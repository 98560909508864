import Config from '@/config';

export default function Footer() {
    return (
        <footer
            className="container-xs bottom-0 mx-auto mt-auto flex items-center justify-center gap-4 py-7 text-center text-sm text-white-a700 sm:flex-col sm:gap-0">
            <a
                href="/terms-privacy"
                className="font-extrabold text-[#00C2FF] transition-all hover:drop-shadow-glow"
            >
                privacy policy & terms of use
            </a>
            <span className="sm:hidden">|</span>
            <span>
                twitter: <a href="https://x.com/polkaidentity" target="_blank" className="font-extrabold text-[#00C2FF] transition-all hover:drop-shadow-glow">@polkaidentity</a>
            </span>
            <span className="sm:hidden">|</span>
            <span>
                matrix: <a href="https://matrix.to/#/#polkaidentity:matrix.org" target="_blank" className="font-extrabold text-[#00C2FF] transition-all hover:drop-shadow-glow">#polkaidentity</a>
            </span>
            <span className="sm:hidden">|</span>
            <div>
                crafted by
                <a
                    target="_blank"
                    href={Config.snowpineUrl}
                    className="font-extrabold text-[#00C2FF] transition-all hover:drop-shadow-glow"
                    rel="noreferrer"
                >
                    {' '}
                    snowpine labs ❆{' '}
                </a>
                2024 - {new Date().getFullYear()}
            </div>
        </footer>
    );
}

import CookieConsent from 'vanilla-cookieconsent';

const Config = {
    appVersion: 'v1.2.0',
    snowpineUrl: 'https://snowpine.io/',
    cookieConsent: {
        categories: {
            necessary: {
                enabled: true, // this category is enabled by default
                readOnly: true, // this category cannot be disabled
            },
            analytics: {},
        },
        language: {
            default: 'en',
            translations: {
                en: {
                    consentModal: {
                        title: 'We use cookies',
                        description:
                            'To optimize your experience on our site, we use cookies. That help us understand and improve how you use our platform. By continuing to browse, you agree to our use of cookies.',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject all',
                        showPreferencesBtn: 'Manage Individual preferences',
                    },
                    preferencesModal: {
                        title: 'Manage cookie preferences',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject all',
                        savePreferencesBtn: 'Accept current selection',
                        closeIconLabel: 'Close modal',
                        sections: [
                            {
                                description:
                                    'We value your privacy. You can choose which types of cookies to allow on our site. Select your preferences below.',
                            },
                            {
                                title: 'Strictly Necessary cookies',
                                description:
                                    'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                                //this field will generate a toggle linked to the 'necessary' category
                                linkedCategory: 'necessary',
                            },
                            {
                                title: 'Performance and Analytics',
                                description:
                                    'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                linkedCategory: 'analytics',
                            },
                            {
                                title: 'More information',
                                description:
                                    'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>',
                            },
                        ],
                    },
                },
            },
        },
    } as CookieConsent.CookieConsentConfig,
};

export default Config;
